export const nl = {
    projects: 'Projecten',
    forms: 'Formulieren',
    organisations: 'Organisaties',
    users: 'Gebruikers',
    surveys: 'Enquetes',
    Crow_project_details: 'Better Performance gegevens',
    Project_details: 'Algemene projectgegevens',
    Client: 'Opdrachtgever',
    client: 'opdrachtgever',
    Contractor: 'Opdrachtnemer',
    contractor: 'opdrachtnemer',
    Branch: 'Vestiging',
    branch: 'vestiging',
    Startdate: 'Startdatum',
    Enddate: 'Einddatum',
    created: 'Aangemaakt',
    finished: 'Afgerond',
    crow_project_ready: 'Start meting',
    contractor_set: 'Opdrachtnemer bepaald',
    form_added: 'Nieuwe meting',
    member_added: 'Projectmedewerker gekoppeld',
    form_ready: 'Invullen gestart',
    form_completed: 'Invullen afgerond',
    form_disagree: 'Revisie aangevraagd',
    projectName: 'Projectnaam',
    members: 'Projectleden',
    projectOwner: 'Projecteigenaar',
    referenceStartDate: 'Startdatum',
    referenceEndDate: 'Einddatum',
    componentStatus: {
        active: 'Actief',
        inactive: 'Niet actief'
    },
    userOrgStatus: {
        active: 'Actief',
        inactive: 'Inactief',
        requested: 'Toegang gevraagd',
        invited: 'Uitgenodigd',
        remote_invited: 'Uitgenodigd',
        remote_added: 'Uitgenodigd'
    },
    projectStatus: {
        new: 'Nieuw',
        start_intermediate_measurement: 'Start tussentijdse meting',
        intermediate_measurement: 'Tussentijdse meting',
        start_final_measurement: 'Start eindmeting',
        final_measurement: 'Eindmeting',
        finishing: 'Project afronden',
        finished: 'Afgerond',
        active: 'Actief'
    },
    formStatus: {
        skipped: 'Overgeslagen',
        concept: 'Concept',
        start_fill: 'Begin met invullen',
        fill: 'Aan het invullen',
        active: 'Actief',
        completed: 'Vastgesteld',
        fill_revision: 'Revisie invullen',
        agreement_companion: 'Akkoord geven',
        pending_revision: 'Wachten op revisie',
        pending_agreement: 'Wachten op akkoord',
        crow_admin_disagreed: 'CROW Beheerder niet akkoord',
        pending_crow_admin: 'Wachten CROW Beheerder',
        sent: 'Verzonden',
        finished: 'Afgerond'
    },
    projectRoles: {
        contactperson: 'Contactpersoon',
        view: 'Bekijken',
        fill: 'Beoordelen',
        admin: 'Beheren en delen',
        invited: 'Openstaande uitnodiging'
    },
    reportRoles: {
        contactperson: 'Contactpersoon', 
        view: 'Alleen inzien', 
        update: 'Inzien en bewerken', 
        admin: 'Rapportage eigenaar'
    },
    reportFilters: {
        noFilters: 'Geen filters', 
        allFilters: 'Alle filters'
    },
    Admin: 'Beheerder',
    Crow_admin: 'CROW Beheerder',
    User: 'Gebruiker',
    roles: {
        user: {
            categoryTitle: 'Gebruikers',
            categoryDescription: 'Rechten m.b.t. organisatie gebruikers.',
            view: {
                label: 'Gebruikers bekijken',
                description: 'Kan alle gebruikers inzien.'
            }
        },
        organisation: {
            categoryTitle: 'Organisaties',
            categoryDescription: 'Rechten m.b.t. organisaties.',
            view: {
                label: 'Gebruikers bekijken',
                description: 'Kan alle gebruikers van uw organisatie inzien.'
            },
            update_details: {
                label: 'Organisatie instellingen bewerken',
                description: 'Kan 2FA en emailextenties van uw organisatie beheren'
            },
            add_role: {
                label: 'Rol toevoegen',
                description: ''
            },
            remove_role: {
                label: 'Rol verwijderen',
                description: ''
            },
            manage_user: {
                label: 'Gebruikersrol bewerken',
                description: 'Kan alle gebruikers van uw organisatie bewerken.'
            },
            invite_user: {
                label: `Collega's uitnodigen`,
                description: ''
            },
            cancel_invite_user: {
                label: 'Uitnodiging annuleren',
                description: ''
            },
            accept_user: {
                label: 'Toegang goedkeuren',
                description: ''
            },
            decline_user: {
                label: 'Toegang afwijzen',
                description: ''
            },
            sso_config: {
                label: 'SSO configureren',
                description: ''
            },
            manage_configurations: {
                label: 'Stijlen en teksten beheren',
                description: ''
            }
        },
        role: {
            categoryTitle: 'Rollen & rechten',
            categoryDescription: 'Rechten m.b.t. organisatie rollen.',
            manage: {
                label: 'Bekijken en bewerken',
                description: ''
            },
            add_permission: {
                label: 'Rechten toevoegen',
                description: ''
            },
            remove_permission: {
                label: 'Rechten verwijderen',
                description: ''
            },
            delete: {
                label: 'Verwijderen',
                description: ''
            }
        },
        project: {
            categoryTitle: 'Projecten',
            categoryDescription: 'Beheerrechten m.b.t. projecten in uw organisatie.',
            view: {
                label: 'Alle projecten bekijken',
                description: 'Kan alle projecten van uw organisatie inzien.'
            },
            create_crow: {
                label: 'Nieuwe projecten aanmaken',
                description: ''
            },
            remove_member: {
                label: 'Projectleden verwijderen',
                description: ''
            },
            fill: {
                label: 'Projectgegevens wijzigen',
                description: 'Kenmerken en formulieren invullen.'
            }
        },
        form: {
            categoryTitle: 'Formulieren',
            categoryDescription: 'Rechten m.b.t. formulieren.',
            view: {
                label: 'Alle formulieren bekijken',
                description: 'Kan alle formulieren van uw organisatie inzien.'
            },
            create: {
                label: 'Aanmaken',
                description: ''
            },
            update: {
                label: 'Gegevens aanpassen',
                description: ''
            },
            ready: {
                label: 'Invullen',
                description: ''
            },
            send: {
                label: 'Vaststellen',
                description: ''
            },
            agree: {
                label: 'Akkoord geven',
                description: '',
                dependencies: ['form__disagree']
            },
            disagree: {
                label: 'Niet akkoord geven',
                description: '',
                dependencies: ['form__agree']
            },
            delete: {
                label: 'Verwijderen',
                description: ''
            }
        },
        crow: {
            categoryTitle: 'CROW',
            categoryDescription: 'Rechten m.b.t. Better Performance.',
            admin: {
                label: 'CROW beheerder',
                description: 'Kan eindbeoordelingen goedkeuren.'
            }
        },
        analysis: {
            categoryTitle: 'Analyse',
            categoryDescription: 'Het kunnen zien van resultaten van alle aannemers.',
            view: {
                label: 'Mag bekijken',
                description: ''
            }
        },
        document: {
            categoryTitle: 'Documenten',
            categoryDescription: 'Rechten m.b.t. documenten.',
            view: {
                label: 'Mag doorzoeken',
            },
            upload: {
                label: 'Mag uploaden'
            },
            edit: {
                label: 'Mag bewerken'
            },
            remove: {
                label: 'Mag verwijderen'
            }
        }
    },
    indicators: {
        client: 'Opdrachtgever',
        contractor: 'Opdrachtnemer',
        branch: 'Vestiging',
        startdate: 'Startdatum',
        enddate: 'Einddatum'
    },
    contexts: {
        project_details: 'Algemene projectgegevens',
        crow_project_details: 'Better Performance gegevens'
    },
    formtemplates: {
        Works_contractor_form_template: 'Better Performance Werken',
        Works_client_form_template: 'Better Performance Werken',
        Services_contractor_form_template: 'Better Performance Diensten',
        Services_client_form_template: 'Better Performance Diensten'
    },
    phases: {
        design: 'Ontwerpfase',
        realisation: 'Realisatiefase',
    },
    clientAverage: "Behaalde score OG", 
    contractorAverage: "Behaalde score ON", 
};
