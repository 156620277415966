<template>
    <div class="settings">
        <div class="header">
            <q-avatar :img="organisation.logo" size="large">{{ getOrgInitials }}</q-avatar>
            <h2 class="paddingless mt-m mb-xs">{{ organisation.name }}</h2>
            <p>{{ getSubtitleText() }}</p>
        </div>
 
        <div class="cards-container">
            <div class="card-wrapper">
                <div class="q-card">
                    <q-section paddingTop="40" hPadding="40" paddingBottom="20" class="content">
                        <div class="title">
                            <q-avatar :img="userAvatar" class="user-avatar" size="medium">{{initials}}</q-avatar>
                            <h3>Mijn account</h3>
                        </div>
                        <p>Beheer je gegevens, privacy en beveiliging, of vraag toegang tot bedrijven op basis van je e-mailadres.</p>
                    </q-section>

                    <q-section vPadding="20" hPadding="40" borderTop class="footer space-between vertical-center">
                        <q-button to="/profile" variation="blank" size="medium">Beheer mijn account</q-button>
                    </q-section>
                </div>
            </div>

            <div class="card-wrapper" v-for="setting in settings" :key="setting.title">
                <div class="q-card">
                    <q-section paddingTop="40" hPadding="40" paddingBottom="20" class="content">
                        <h3>{{ setting.title }}</h3>
                        <p>{{ setting.text }}</p>
                    </q-section>

                    <q-section v-if="setting.button" vPadding="20" hPadding="40" borderTop class="footer space-between vertical-center">
                        <q-button :to="setting.to" variation="blank" size="medium">{{ setting.button }}</q-button>
                        <q-badge v-if="setting.badge" :value="setting.badge"></q-badge>
                    </q-section>

                    <div v-if="setting.items" class="footer-items">
                        <q-section class="item space-between vertical-center" v-for="item in setting.items" :key="item.name" borderTop vPadding="12" hPadding="40">
                            <span>{{ item.name }}</span>
                            <q-button :to="item.to" variation="blank" size="medium">{{ item.label }}</q-button>
                        </q-section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ORGANISATION } from '@/graphql/queries';
import { companyInitials, isAdminRole } from '@/assets/js/utils';

export default {
    name: 'Settings',
    data() {
        return {
            organisation: this.$store.getters.getCurrentOrganisation,
            ssoSettings: {
                title: 'Single Sign-On',
                text: 'Configureer hier uw Identity Provider middels het SAML protocol.',
                button: 'Single sign-on instellen',
                to: '/settings/sso',
                subTitleText: 'producten',
                requiresPermissions: ['Organisation__sso_config']
            },
            integrationSettings: {
                title: 'Integraties',
                text: 'Configureer hier integraties zoals de publieke API.',
                button: 'Configureer integraties',
                to: '/settings/integrations',
                subTitleText: 'publieke API',
                requiresPermissions: []
            },
            userRequests: null,
            userAvatar: this.$store.getters.getUser.avatar,
        };
    },
    methods: {
        getInitials(name) {
            return name.split(' ').map(subText => subText? subText[0] : '').join('');
        },
        getSubtitleText() {
            let words = this.settings.map(s => s.subTitleText);
            const length = words.length;

            let str = 'Bekijk en wijzig hier de instellingen voor ';

            words.forEach((word, key) => {
                str += word;
                if (key < length - 1) str += key == length - 2 ? ' en ' : ', ';
            });
            return str;
        },
        getSettingsMenu(settings) {
            const vm = this;
            return settings.filter(setting => {
                // initialize result as true if there are no requiredPermissions.
                let result = setting.requiresPermissions.length === 0;
                for (let i = 0; i < setting.requiresPermissions.length; i++) {
                    const slug = setting.requiresPermissions[i];
                    const slugArray = slug.split('__');
                    const entity = slugArray[0];
                    const permission = slugArray[1];

                    result = vm.ability.get().can(permission, entity);

                    if (result) break;
                }

                return result;
            });
        }
    },
    computed: {
        initials: function() {
            return this.getInitials(`${this.$store.getters.getUser.firstName} ${this.$store.getters.getUser.lastName}`);
        },
        getOrgInitials() {
            return companyInitials(this.organisation.name, 2)
        },
        settings() {
            let settings = [...this.defaultSettings];
            
            if (this.__MOD('sso')) {
                settings.push(this.ssoSettings);
            }
            
            if (this.__MOD('api')) {
                settings.push(this.integrationSettings);
            }
            
            const menu = this.getSettingsMenu(settings);
            
            const usersItem = menu.find(nav => nav.to === '/settings/users');
            if (usersItem) {
                usersItem.badge = this.userRequests === 1 ? `${this.userRequests} verzoek` : this.userRequests > 1 ? `${this.userRequests} verzoeken` : null;
            }
            
            return menu;
        },
        defaultSettings: function() {
            const settings = [
                {
                    title: "Collega's",
                    text: `Nodig collega’s uit voor de applicatie en bepaal de toegangsrechten en mogelijkheden.`,
                    button: "Beheer je collega's",
                    to: '/settings/users',
                    subTitleText: "collega's",
                    requiresPermissions: ['User__view', 'Organisation__user_invite']
                },
                {
                    title: 'Informatiebeveiliging',
                    text: 'Pas de instellingen rondom beveiliging aan en pas rollen en rechten aan.',
                    button: 'Beheer je organisaties',
                    to: '/settings/security',
                    subTitleText: 'informatiebeveiliging',
                    requiresPermissions: [
                        'Organisation__add_role',
                        'Organisation__remove_role',
                        'Organisation__update_details'
                    ]
                }
            ]

            const hasQfactPD = this.$store.getters.hasQfactPD;
            const hasPDFull = this.$store.getters.hasPDFullProduct;

            const items = [
                {
                    name: 'Prestaties (vragen en kenmerken)',
                    to: '/manage/labels',
                    label: 'Open',
                    active: hasQfactPD
                },
                {
                    name: 'Formulieren',
                    to: '/manage/formtemplates',
                    label: 'Open',
                    active: hasPDFull
                },
                {
                    name: 'Projecttemplates',
                    to: '/manage/projecttemplates',
                    label: 'Open',
                    active: hasQfactPD
                },
                {
                    name: 'Formulierstijlen',
                    to: '/settings/styles',
                    label: 'Open',
                    active: hasQfactPD && this.ability.get().can('manage_configurations', 'Organisation')
                },
                {
                    name: 'Standaard formulierteksten',
                    to: '/settings/templates',
                    label: 'Open',
                    active: hasQfactPD && this.ability.get().can('manage_configurations', 'Organisation')
                }
            ]

            if(isAdminRole(this.$store.getters.getOrganisationRole) && hasQfactPD)
                settings.push({
                    title: 'Beheer',
                    text: 'Bekijk en wijzig hier de producten, templates en instellingen voor jouw organisatie.',
                    items: items.filter(item => item.active),
                    subTitleText: 'algemeen beheer',
                    requiresPermissions: []
                })

            return settings
        },
        organisationType: function () {
            return this.$store ? this.$store.getters.getOrganisationType : 'contractor';
        },
    },
    apollo: {
        currentOrganisation: {
            query: ORGANISATION,
            variables() {
                return {
                    organisationId: this.$store.getters.getCurrentOrganisation.id
                };
            },
            fetchPolicy: 'no-cache',
            update: data => data.organisation,
            result(res) {
                this.organisation = res.data.organisation;
                this.$store.commit('setCurrentOrganisation', res.data.organisation);
                this.userRequests = res.data.organisation.users.filter(user => user.status === 'requested').length;
            }
        }
    }
};
</script>

<style scoped lang="scss">
.cards-container {
    --gap: 20px;
    display: flex;
    justify-content: space-between;
    margin-inline: auto;
    flex-wrap: wrap;
    gap: var(--gap);
    padding-top: 40px;
    width: 90%;
    max-width: 980px;

    .card-wrapper {
        flex-grow: 1;
        max-width: calc(50% - var(--gap));
        margin-bottom: 40px;
        
        .q-card {
            margin-inline: auto;

            .content {
                h3 {
                    font-family: Gotham;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 26px;
                    line-height: 26px;
                    margin-bottom: 19px;
                }

                p {
                    font-family: Gotham;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 22px;
                }
            }

            .footer {
            }

            .footer-items {
                display: flex;
                flex-direction: column;

                .item {
                    // display: flex;
                    // justify-content: space-between;
                    // align-items: center;
                    font-family: Gotham;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 22px;
                }
            }
        }
    }
}

.header {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    p {
        width: 400px;
        text-align: center;
    }
}

h2 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 31px;
    line-height: 33px;
    padding: 30px 0;
}

.settings {
    padding-top: 40px;
}

.title {
    display: flex;
}

.user-avatar {
    margin: -10px 10px 0 0;
}
</style>
