<template>
    <div class="survey-point" :class="{ hover }">
        <!-- new survey input -->
        <div v-if="surveyPoint.isCreatePoint || surveyPoint.isBeingNamed || surveyPoint.isDuplicating" class="create-survey">
            <!-- button -->
            <div v-if="!edit" class="button">
                <q-tooltip :disabled="!surveyPoint.reasons || !surveyPoint.reasons.length">
                    <template v-slot:tooltip>
                        <p v-html="surveyPoint.reasons[0]"></p>
                    </template>
                    <q-button
                        key="create-new"
                        icon="survey"
                        size="small"
                        variation="ghost"
                        :disabled="!surveyPoint.able"
                        @click="handleStartNewForm"
                    >Nieuw formulier</q-button>
                </q-tooltip>
            </div>

            <!-- choose template -->
            <div v-else-if="edit === 'select'" class="select">
                <q-dropdown
                    class="template-dropdown"
                    v-model="selectedOption"
                    size="small"
                    placeholder="Selecteer formuliertype"
                    :options="surveyPoint.options"
                    :disabled="!canAnswerIndicator"
                    autocomplete
                    autofocus
                ></q-dropdown>
                <q-button
                    key="action-button"
                    class="select-button"
                    size="small"
                    :disabled="!selectedOption"
                    @click="handleSelectOption"
                >Volgende</q-button>
            </div>
            <!-- define name -->
            <div v-else-if="edit === 'name'" class="name">
                <div class="left">
                    <q-tag :variation="surveyVariation" size="medium">{{ $t(`formStatus.${surveyPoint.form.status}`) }}</q-tag>
                </div>
                <div class="input-with-template">
                    <q-clear-input
                        id="enquete-name-input"
                        class="text-input"
                        v-model="surveyPoint.form.name"
                        type="text"
                        size="small"
                        placeholder="Typ formuliernaam"
                        @enter="handleCreateSurvey"
                    />
                    <p class="template-name">{{ surveyPoint.form.template.name }}</p>
                </div>
                <div class="button">
                    <q-button
                        :disabled="!surveyPoint.form.name.length"
                        size="small"
                        style="padding-left: 16px"
                        :loading="surveyPoint.creatingNewSurvey || loading"
                        @click.stop="handleCreateSurvey"
                    >{{ surveyPoint.id.includes(`create-survey-`) || surveyPoint.isDuplicating ? 'Toevoegen' : 'Wijzigen' }}</q-button
                    >
                </div>
            </div>
        </div>

        <!-- survey point -->
        <div v-else class="survey">
            <div class="left">
                <div class="tag">
                    <q-tag size="medium" :variation="surveyVariation">{{ $t(`formStatus.${surveyPoint.form.status}`) }}</q-tag>
                </div>
                <div class="survey-name">
                    <span class="name">{{ surveyPoint.form.name }}</span>
                    <span class="template">{{ surveyPoint.form.template.name }}</span>
                </div>
            </div>
            <div class="right">
                <q-tooltip :disabled="!surveyPoint.responsesTooltip">
                    <template v-slot:tooltip>
                        <p v-html="surveyPoint.responsesTooltip"></p>
                    </template>
                    <q-tag class="responses-tag" size="small" variation="default">
                        <q-icon type="users-filled" color="#495057" width="16" height="16"></q-icon>
                        <span>{{ surveyPoint.form.responses ? surveyPoint.form.responses.length : 0 }}</span>
                    </q-tag>
                </q-tooltip>
                <q-tag v-if="showScore" size="small" variation="success" class="score-tag">
                    <q-score-text size="14" :score="surveyPoint.form.contractorScoreData.average" color="#37B24D"></q-score-text>
                </q-tag>
                <q-options
                    v-if="canAnswerIndicator && isAdminRole"
                    class="options"
                    :options="enqueteOptions"
                    @input="handleSurveyOptionSelected"
                    color="rgb(174, 181, 188)"
                ></q-options>
            </div>
        </div>

    </div>
</template>

<script>
import { isDefined, executeParentFunction, isAdminRole } from '@/assets/js/utils';
import _ from 'lodash';

import { UPDATE_FORM, DELETE_FORM } from '@/graphql/mutations';

export default {
    name: 'survey-point',
    props: {
        point: {
            type: Object,
            required: true
        },
        project: {
            type: Object,
            required: true
        },
        hover: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            surveyPoint: this.point,
            cachedSurveyPoint: _.cloneDeep(this.point),
            edit: '',
            selectedOption: '',
            enqueteOptions: [
                {
                    name: 'Naam wijzigen',
                    value: 'change-name'
                },
                {
                    name: 'Dupliceren',
                    value: 'duplicate'
                },
                {
                    name: 'Verwijderen',
                    value: 'delete',
                    color: 'red'
                }
            ],
            loading: false
        }
    },
    methods: {
        handleStartNewForm() {
            this.edit = 'select';
            this.surveyPoint.isBeingCreated = true;

            executeParentFunction(this, 'project-timeline', 'handlePointUpdated', this.surveyPoint);
        },
        handleCancelNewForm(emit = true) {
            this.edit = '';
            this.surveyPoint = _.cloneDeep(this.cachedSurveyPoint);
            if(this.surveyPoint.form) this.surveyPoint.form.name = '';
            this.surveyPoint.creatingNewSurvey = false;
            this.surveyPoint.isBeingCreated = false;
            this.surveyPoint.isBeingNamed = false;
            this.surveyPoint.isDuplicating = false;
            this.surveyPoint.form.template = null;
            this.selectedOption = '';

            if(emit) executeParentFunction(this, 'project-timeline', 'handlePointUpdated', this.surveyPoint);
        },
        handleCancelChangeName() {
            this.edit = '';
            this.surveyPoint = _.cloneDeep(this.cachedSurveyPoint);
            this.surveyPoint.isBeingCreated = false;
            this.surveyPoint.isBeingNamed = false;
            this.surveyPoint.isCreatePoint = false;
            executeParentFunction(this, 'project-timeline', 'handlePointUpdated', this.surveyPoint);
        },
        handleSelectOption() {
            const option = this.surveyPoint.options.find(option => option.value === this.selectedOption);
            this.edit = 'name';
            this.surveyPoint.form.template = {
                id: option.value,
                name: option.label
            };

            executeParentFunction(this, 'project-timeline', 'handlePointUpdated', this.surveyPoint);

            this.focusInput();
        },
        handleSurveyOptionSelected(option) {
            if(option.value === 'change-name') {
                this.surveyPoint.isBeingNamed = true;
                executeParentFunction(this, 'project-timeline', 'handlePointUpdated', this.surveyPoint);

                this.focusInput();
            } else if(option.value === 'duplicate') return this.handleDuplicateSurvey()
            else if(option.value === 'delete') return this.handleDeleteSurvey()
        },
        handleDuplicateSurvey() {
            const clonedPoint = _.cloneDeep(this.surveyPoint);
            const duplicatedSurvey = {
                ...clonedPoint,
                id: `create-survey-${this.surveyPoint.phaseId}`,
                form: {
                    ...clonedPoint.form,
                    name: `${this.surveyPoint.form.name} kopie`,
                    status: 'concept',
                    statusLabel: 'concept',
                    responseIds: [],
                    responses: [],
                    contractorScoreData: {
                        average: null
                    }
                },
                isDuplicating: true
            };

            executeParentFunction(this, 'project-timeline', 'handlePointUpdated', duplicatedSurvey);
        },
        handleDeleteSurvey() {
            this.loading = true;

            this.$apollo.mutate({
                mutation: DELETE_FORM,
                variables: {
                    id: this.surveyPoint.id
                }
            })
            .then(result => {
                this.loading = false;
                this.$store.commit('notify', { type: 'success', message: 'Formulier succesvol verwijderd' });
                executeParentFunction(this, 'project-timeline', 'handlePointRemoved', this.surveyPoint);
            })
            .catch(error => {
                this.loading = false;
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het verwijderen van het formulier' });
            })
        },
        parseSurveyPoint() {
            let responsesTooltip = '';

            const responses = this.point.form.responses || [];
            responses.forEach((response, index) => {
                const name = response.emetMeta.userName || response.emetMeta.email;
                if(response.scoreData && !isNaN(response.scoreData.average)) {
                    const score = response.scoreData.average.toFixed(1);
                    responsesTooltip += `<div style="display:flex;justify-content:space-between;gap:12px;"><span>${name}</span><b>${score}</b></div>`;
                } else responsesTooltip += `<div${name}</div>`;
            });

            this.surveyPoint = _.cloneDeep({
                ...this.point,
                responsesTooltip
            });
            
            if(!this.surveyPoint.isDuplicating) this.cachedSurveyPoint = _.cloneDeep(this.surveyPoint);

            if(this.surveyPoint.isBeingNamed || this.surveyPoint.isDuplicating) {
                this.edit = 'name';
                this.focusInput();
            }
        },
        async handleCreateSurvey() {
            if (!this.surveyPoint.form.name) return;

            const formName = this.surveyPoint.form.name.trim();
            const nameExists = this.project.timeline.points.some(point => isDefined(point.form) && point.form.name.toLowerCase() === formName.toLowerCase());

            if (nameExists) {
                this.$store.commit('notify', {
                    type: 'info',
                    message: 'Je kunt geen formulier aanmaken met een naam die al gebruikt is',
                });
                this.focusInput();
                return
            }

            if(this.surveyPoint.isBeingNamed) return this.handleUpdateName()

            this.surveyPoint.creatingNewSurvey = true;
            
            executeParentFunction(this, 'project-timeline', 'handlePointUpdated', this.surveyPoint);
            if(this.surveyPoint.isDuplicating) await executeParentFunction(this, 'project', 'handleDuplicateSurvey', { formId: this.surveyPoint.form.id, name: this.surveyPoint.form.name });
            else await executeParentFunction(this, 'project', 'handleCreateSurvey', this.surveyPoint);
        },
        handleUpdateName() {
            this.loading = true;
            
            this.$apollo.mutate({
                mutation: UPDATE_FORM,
                variables: {
                    id: this.surveyPoint.id,
                    name: this.surveyPoint.form.name
                }
            })
            .then(result => {
                this.loading = false;
                this.$store.commit('notify', { type: 'success', message: 'Formuliernaam succesvol aangepast' });
                this.cachedSurveyPoint.form.name = this.surveyPoint.form.name;
                executeParentFunction(this, 'project-timeline', 'handlePointUpdated', this.surveyPoint);
                this.handleCancelChangeName();
            })
            .catch(error => {
                this.loading = false;
                this.$store.commit('notify', { type: 'danger', message: 'Er ging iets fout tijdens het aanpassen van het formulier' });
            })
        },
        focusInput() {
            this.$nextTick(() => {
                document.getElementById('enquete-name-input')?.focus();
            });
        }
    },
    computed: {
        canAnswerIndicator: function() {
            return executeParentFunction(this, 'project', 'canEditIndicator', this.surveyPoint);
        },
        surveyVariation: function() {
            switch (this.surveyPoint.form.status) {
                case 'concept':
                    return 'default';
                case 'sent':
                    return 'warning';
                case 'finished':
                    return 'success';
                default:
                    return 'default';
            }
        },
        showScore: function() {
            return isDefined(this.surveyPoint.form.contractorScoreData?.average)
        },
        phasePointsLength: function() {
            const phase = this.project.timeline.phases.find(phase => phase.id === this.point.phaseId);
            return phase?.pointIds?.length
        },
        isAdminRole: function() {
            return isAdminRole(this.$store.getters.getOrganisationRole)
        }
    },
    watch: {
        point: {
            handler(after, before) {
                this.parseSurveyPoint();
            },
            deep: true
        },
        phasePointsLength() {
            if(this.point.id.includes('create-')) this.handleCancelNewForm()
        }
    },
    created() {
        this.parseSurveyPoint();
    }
}
</script>

<style lang="scss" scoped>
@import '../../../../../components/qds/assets/style/_variables.scss';
@import '../../../../../components/qds/assets/style/fonts/fonts.css';

.survey-point {
    flex-grow: 1;

    &.hover {
        .survey {
            .right {
                .options {
                    transform: scale(1);
                    opacity: 1;
                    margin-right: 0;
                    pointer-events: all;
                    transition: 350ms cubic-bezier(.51,1.6,.45,1.08);
                    -webkit-transition: 350ms cubic-bezier(.51,1.6,.45,1.08);
                }
            }
        }
    }

    .create-survey {
        .select-button {
            width: 100px;
        }

        .select {
            display: flex;
            gap: 8px;
        }

        .name {
            display: flex;
            gap: 8px;
            flex-grow: 1;

            .input-with-template {
                position: relative;
                flex-grow: 1;
                margin-top: -8px;

                .text-input {
                    padding-bottom: 0;
                }
    
                .template-name {
                    position: absolute;
                    font-size: 13px;
                    line-height: 15px;
                    color: $color-grey-5;
                    bottom: -2px;
    
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }

    .survey {
        display: flex;
        align-items: center;

        .left {
            display: flex;
            align-items: center;
            gap: 8px;
            
            .survey-name {
                display: flex;
                flex-direction: column;
                gap: 2px;
                font-size: 14px;
                line-height: 15px;

                > span {
                    max-width: 280px;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }

                .template-name {
                    -webkit-line-clamp: 1;
                }

                :nth-child(2) {
                    color: $color-grey-5;
                    font-size: 13px;
                }
            }
        }

        .right {
            --gap: 8px;

            position: absolute;
            right: 12px;
            
            display: flex;
            align-items: center;
            gap: var(--gap);

            .options {
                margin-right: calc((22px + var(--gap)) * -1);
                transform: scale(0);
                opacity: 0;
                pointer-events: none;
                transition: 350ms cubic-bezier(.51,1.6,.45,1.08);
                -webkit-transition: 350ms cubic-bezier(.51,1.6,.45,1.08);
            }
        }
    }
}

</style>